function Background()
{
    return (
        <>
        <div className="bg_admin">
            
        </div>
        </>
    );
}

export default Background;