import { Link } from "react-router-dom";
import MGMTLogo from '../../../assets/icons/MGMTLogo.png'
function CompanySidebar()
{
    return (
        <div className="side-bar">
            <div className="MGMTLogo">
                <Link href="/company-management">
                    <img src={MGMTLogo} alt="mgmt logo" />
                </Link>
            </div>
            <div className="sidebar-icons">
                <Link>
                    <span className="icon-span">
                        <i className="fa-solid fa-house"></i>
                    </span>
                    <span className="text-span">Home</span>
                </Link>
                <Link>
                    <span className="icon-span">
                        <i className="fa-solid fa-tv"></i>
                    </span>
                    <span className="text-span">Presentation</span>
                </Link>
                <Link>
                    <span className="icon-span">
                        <i className="fa-solid fa-trophy"></i>
                    </span>
                    <span className="text-span">Score</span>
                </Link>
                <Link>
                    <span className="icon-span">
                        <i className="fa-solid fa-section"></i>
                    </span>
                    <span className="text-span">Compliance</span>
                </Link>
                <Link>
                    <span className="icon-span">
                        <i className="fa-solid fa-calculator"></i>
                    </span>
                    <span className="text-span">Office</span>
                </Link>
            </div>
        </div>
    );
}

export default CompanySidebar;