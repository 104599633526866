import React from 'react';

const Input = ({ id, name, label, placeholder, type = 'text', colClass = 'col-md-4 mb-3'}) => {
    return (
        <div className={colClass}>
            <div className="form-group">
                <label htmlFor={id} className="col-form-label">{label}</label>
                <input 
                    type={type} 
                    name={name} 
                    id={id} 
                    placeholder={placeholder} 
                    className="form-control input_field" 
                        
                />
            </div>
        </div>
    );
};

export default Input;
