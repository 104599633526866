import React from "react";
import { Helmet } from "react-helmet"; 
const CustomerManagement = () => {
  return (
    <>
      <Helmet>
        <title>Cutomer Management System</title> 
      </Helmet> 

    </>
  );
};

export default CustomerManagement;
