import React from 'react';
import { Link, useLocation } from 'react-router-dom';

const CompanySideBar = () => {
    const location = useLocation();

    const isActive = (path) => {
        return location.pathname === path ? "active" : "";
    };

    return (
        <div className="side-nav">
            <div className="sidebar-body">
                <ul className='nav_list'>
           
                    <li className='nav_items'>
                        <Link to="/company" className={`navLink ${isActive("/company")}`} title='Company'>
                            <i className="ri-building-line"></i>
                        </Link>
                    </li>
                    <li className='nav_items'>
                        <Link to="/company-process" className={`navLink ${isActive("/company-process")}`} title='Company Processes'>
                            <i className="ri-settings-4-line"></i>
                        </Link>
                    </li>
                   
                    <li className='nav_items'>
                        <Link to="/summary" className={`navLink ${isActive("/summary")}`} title='Summary'>
                            <i className="ri-bar-chart-2-line"></i>
                        </Link>
                    </li>
                   <li className='nav_items'>
                        <Link to="/settings" className={`navLink ${isActive("/settings")}`} title='Settings'>
                            <i className="ri-cloud-line"></i>
                        </Link>
                    </li>
                
                </ul>
            </div>
        </div>
    );
};

export default CompanySideBar;
