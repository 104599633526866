import { Link } from "react-router-dom";
function CompanyArea()
{
    return (
        <div className="company-area">
            {/* <div>
                <Link style={{padding:'4rem',backgroundColor:'#2A92EE',borderRadius:'10px',color:'white'}} to="/company">Company</Link>
            </div> */}
        </div>
    );
}

export default CompanyArea;