import React from 'react';
import { Link, useLocation } from 'react-router-dom';

const SideBar = () => {
    const location = useLocation();

    const isActive = (path) => {
        return location.pathname === path ? "active" : "";
    };

    return (
        <div className="side-nav">
            <div className="sidebar-body">
                <ul className='nav_list'>
                    <li className='nav_items'>
                        <Link to="/home" className={`navLink ${isActive("/home")}`} title='Home'>
                            <i className="ri-home-5-line"></i>
                        </Link>
                    </li>
                  
                    <li className='nav_items'>
                        <Link to="/settings" className={`navLink ${isActive("/settings")}`} title='Settings'>
                            <i className="ri-trophy-line"></i>
                        </Link>
                    </li>
                </ul>
            </div>
        </div>
    );
};

export default SideBar;
