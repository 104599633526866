import BG from '../../../assets/videos/bg.mp4';
import Logo from '../../../assets/icons/MGMTLogo.png';
import React, {useState} from 'react';
import { Link } from 'react-router-dom';

const CompanyComponent = () => {
    const [showExtraButtons, setShowExtraButtons] = useState(false);
    const [showForm, setShowForm] = useState(false);

    const handleDivClick = (event) => {
      if (event.target.tagName !== "IMG") {
        setShowExtraButtons(true);
      }
    };
  
    const handleClose = (event) => {
      event.stopPropagation();  // Prevent click from reaching the parent element
      setShowExtraButtons(false);
    };

    const toggleForm = () => {
        setShowForm(!showForm);
      };
    
      const clearInputsAndCloseForm = () => {
        setShowForm(false);
      };

  return (
    <>
      <div className="company-background" >
        <video id="bg-vid" autoPlay muted loop>
          <source src={BG} type="video/mp4" />
        </video>
      </div>

        {/* Container */}
      <div className="container-right">
        <div className="head">
          <div className="header-1">Unternehmen</div>
          <div className="header-2" onClick="#">
            <i className="fa-solid fa-eye-slash"></i>
          </div>
          <div className="header-3" onClick={toggleForm}>
            <i className="fa-solid fa-plus"></i>
          </div>
        </div>

        <div className="company-1" id="com-01" onClick={handleDivClick}>
          <div className="Info">
            <a href="#">
              <img src={Logo} alt="Company Logo" />
            </a>
          </div>
          
          {/* Extra buttons */}
          <div className={`extra-div left ${showExtraButtons ? 'show-div' : ''}`}><Link to="/company" style={{color:'inherit', textDecoration:'none'}}>Datenbank</Link></div>
          <div className={`extra-div middle ${showExtraButtons ? 'show-div' : ''}`} onClick={toggleForm}>Interface</div>
          <div className={`extra-div right ${showExtraButtons ? 'show-div' : ''}`} onClick={handleClose}>
            <i className="fa-solid fa-xmark"></i>
          </div>
    
          <div className={`extra-div ground ${showExtraButtons ? 'show-div' : ''}`}></div>
          <div className="Info"></div>
          <div className="Info"></div>
          <div className="Info"></div>
          <div className="Info"></div>
          <div className="Info"></div>
          <div className="Info"></div>
          <div className="Info"></div>
        </div>
      </div>

        {/* Formular */}
        {showForm && (
        <div className="formular">
          <div className="formular-content">
            <div className="formular-head">Unternehmen</div>
            <div className="formular-body">
              <form id="companyForm" onSubmit={(e) => e.preventDefault()}>
                <input className='input-add' type="text" id="field1" name="field1" placeholder="Name des Unternehmens" />
                <input className='input-add' type="text" id="field2" name="field2" placeholder="Datum der Aufnahme" />
                <input className='input-add' type="text" id="field3" name="field3" placeholder="URL des Logos" />
                <button type="submit" id="submitButton">Anlegen</button>
                <button type="button" id="closeFormButton" onClick={clearInputsAndCloseForm}>Schliessen</button>
              </form>
            </div>
          </div>
        </div>
      )}
      
    </>
  );
};

export default CompanyComponent;
