import React from 'react'

const CardHeader = ({ cardTitle }) => {
    return (
        <>
            <div className="card-header">
                <h6 className="mb-0">{cardTitle} </h6>
            </div>
        </>
    )
}

export default CardHeader