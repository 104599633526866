import { Link } from "react-router-dom";

function LoginSection()
{
    return(
        <div className="login-section-main">
            <div className="login-section-label bold-heading font-2rem flex">Login<div className="text-blue">.</div></div>
            <form className="login-form" action="#">
                <div className="admin-email">
                    <input className="inputs admin-email-input text-montserrat" type="email" placeholder="Email"
                    value="christof.preuss@i-mgmt.ch" 
                    readOnly/>
                </div>
                <div className="admin-password">
                    <input className="inputs text-montserrat" type="password" placeholder="Password" value="12345678" readOnly/>
                </div>
                <div className="admin-login-button-main">
                    <Link className="admin-login-button" to="/company-management">
                    <input className="text-montserrat" type="submit" name="submit" id="btn-submit" value="Login" style={{backgroundColor:'inherit', color:'white'}} /></Link>
                    <a className="admin-forgot-password" href="#">forgot password</a>
                </div>
            </form>
        </div>
    );
}

export default LoginSection;