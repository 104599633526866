import React from 'react';

const Header = ({ title }) => {
  return (
    <header className='nav-header'>
      <h5 className='mb-0'>{title}</h5>
    </header>
  );
};

export default Header;
