import React from 'react';
import Header from './Header';
import { Outlet, useLocation } from "react-router-dom";
import CompanySideBar from './CompanySideBar';

const UserLayout = () => {
  const location = useLocation();
  
  // Set a title based on the route path
  const getTitle = () => {
    switch (location.pathname) {
    
      case '/customer-management':
        return 'Customer Management System';
      case '/company':
        return 'Company Information';
      case '/company-process':
        return 'Company Process';
      case '/settings':
        return 'Settings';
      case '/summary':
        return 'Summary';
      default:
        return 'Admin Page';
    }
  };

  return (
    <div className="page-wrapper">
      <div className="sidebar-wrapper">
        <CompanySideBar />
      </div>
      <div className="body-wrapper">
        <Header title={getTitle()} /> {/* Pass title to Header */}
        <main className='main-wrapper'>
          <div className="content-wrapper">
            <Outlet /> {/* Render the current route's component */}
          </div>
        </main>
      </div>
    </div>
  );
};

export default UserLayout;
