import React, { useState } from "react";
import { v4 as uuidv4 } from "uuid";
import InputField from "../components/InputField";

const ProcessTab = ({ title, description, bgColor }) => {
  const [subTabs, setSubTabs] = useState([]);

  const addSubTab = () => {
    setSubTabs((prevSubTabs) => [
      ...prevSubTabs,
      {
        id: uuidv4(),
        rows: [
          {
            id: uuidv4(),
            department: "",
            short: "",
            target: "",
            date: "",
            check: "",
            method: "",
            potential: "",
            tools: "",
            knowledge: "",
            total: "",
          },
        ],
      },
    ]);
  };

  const removeSubTab = (subTabId) => {
    setSubTabs((prevSubTabs) => prevSubTabs.filter((subTab) => subTab.id !== subTabId));
  };

  const addRow = (subTabId) => {
    setSubTabs((prevSubTabs) =>
      prevSubTabs.map((subTab) =>
        subTab.id === subTabId
          ? {
              ...subTab,
              rows: [
                ...subTab.rows,
                {
                  id: uuidv4(),
                  department: "",
                  short: "",
                  target: "",
                  date: "",
                  check: "",
                  method: "",
                  potential: "",
                  tools: "",
                  knowledge: "",
                  total: "",
                },
              ],
            }
          : subTab
      )
    );
  };

  const removeRow = (subTabId, rowId) => {
    setSubTabs((prevSubTabs) =>
      prevSubTabs.map((subTab) =>
        subTab.id === subTabId
          ? {
              ...subTab,
              rows: subTab.rows.filter((row) => row.id !== rowId),
            }
          : subTab
      )
    );
  };

  return (
    <div>
      <div className={`card-header mb-2 d-flex justify-content-between align-items-center bg-${bgColor}`}>
        <h6 className="mb-0">{title}</h6>
        <div>
          <span className="plus_btn" onClick={addSubTab}>
            <i className="ri-add-line"></i>
          </span>
        </div>
      </div>
      <div className="subTap-wrapper">
        {subTabs.map((subTab) => (
          <div key={subTab.id} className="subTab">
            <div className={`card-header subheader mb-0 bg-${bgColor}`}>
              <div className="mb-2 d-flex justify-content-between align-items-center">
                <div className="col-md-3 mb-2">
                <InputField
                  InputType="text"
                  InputId=''
                  inputName=''
                  inputVal={`${description}`}
                  />
                  {/* <h6 className="mb-0">{description}</h6> */}
                </div>
                <div>
                  <span className="close_btn" onClick={() => removeSubTab(subTab.id)}>
                    <i className="ri-close-line text-danger fw-bold"></i>
                  </span>
                </div>
              </div>
              <div className="row g-0">
                <div className="col-md-8">
                  <InputField
                    InputType="text"
                    InputId=""
                    inputName=""
                    inputVal={`The ${description} process gives an overview of the company's situation and supports in steering the correct decisions`}
                  />
                </div>
                <div className="col-md-4 d-flex">
                  <span className="bg-secondary badge rounded-0">Rating</span>
                  <span className="bg-secondary badge rounded-0">Rating</span>
                  <span className="bg-secondary badge rounded-0">Rating</span>
                  <span className="bg-secondary badge rounded-0">Rating</span>
                  <span className="bg-secondary badge rounded-0">Rating</span>
                </div>
              </div>
            </div>

            <div className="table-responsive innerTab">
              <table className="table table-bordered mb-1">
                <thead className={`bg-${bgColor}`}>
                  <tr>
                    <th style={{ width: '200px' }}>Subprocess</th>
                    <th>Dep. Short</th>
                    <th>Target</th>
                    <th>Date</th>
                    <th>Check</th>
                    <th>Method</th>
                    <th>Potential</th>
                    <th>Tools</th>
                    <th>Knowledge</th>
                    <th>Total</th>
                    <th style={{ width: '150px' }}>Action</th>
                  </tr>
                </thead>
                <tbody>
                  {subTab.rows.map((row, index) => (
                    <tr key={row.id}>
                      <td>
                        <InputField
                          InputType="text"
                          InputId={`department_${index}`}
                          inputName={`department_${index}`}
                          inputVal={row.department}
                        />
                      </td>
                      <td>
                        <InputField
                          InputType="text"
                          InputId={`short_${index}`}
                          inputName={`short_${index}`}
                          inputVal={row.short}
                        />
                      </td>
                      <td className="large_w">
                        <InputField
                          InputType="text"
                          InputId={`target_${index}`}
                          inputName={`target_${index}`}
                          inputVal={row.target}
                        />
                      </td>
                      <td>
                        <InputField
                          InputType="text"
                          InputId={`date_${index}`}
                          inputName={`date_${index}`}
                          inputVal={row.date}
                        />
                      </td>
                      <td>
                        <InputField
                          InputType="text"
                          InputId={`check_${index}`}
                          inputName={`check_${index}`}
                          inputVal={row.check}
                        />
                      </td>
                      <td>
                        <InputField
                          InputType="text"
                          InputId={`method_${index}`}
                          inputName={`method_${index}`}
                          inputVal={row.method}
                        />
                      </td>
                      <td>
                        <InputField
                          InputType="text"
                          InputId={`potential_${index}`}
                          inputName={`potential_${index}`}
                          inputVal={row.potential}
                        />
                      </td>
                      <td>
                        <InputField
                          InputType="text"
                          InputId={`tools_${index}`}
                          inputName={`tools_${index}`}
                          inputVal={row.tools}
                        />
                      </td>
                      <td>
                        <InputField
                          InputType="text"
                          InputId={`knowledge_${index}`}
                          inputName={`knowledge_${index}`}
                          inputVal={row.knowledge}
                        />
                      </td>
                      <td>
                        <InputField
                          InputType="text"
                          InputId={`total_${index}`}
                          inputName={`total_${index}`}
                          inputVal={row.total}
                        />
                      </td>
                      <td valign="middle" >
                        <div className="d-flex align-items-center justify-content-center">

                      <button
                          type="button"
                          className="enter_btn d-flex bg-transparent fw-bold text-primary me-2"
                          
                          >
                          <i class="ri-arrow-left-circle-line"></i> <span>Enter</span>
                        </button>
                        <button
                          type="button"
                          className="remove_btn d-flex bg-transparent fw-bold text-danger"
                          onClick={() => removeRow(subTab.id, row.id)}
                          >
                          <i className="ri-indeterminate-circle-line"></i> <span>Remove</span>
                        </button>
                          </div>
                      </td>
                    </tr>
                  ))}
                </tbody>
                <tfoot>
                  <tr>
                    <td colSpan="100%" className="text-center">
                      <button
                        type="button"
                        className="addmore_btn bg-transparent fw-bold"
                        onClick={() => addRow(subTab.id)}
                      >
                        <i className="ri-add-circle-line"></i> Add More
                      </button>
                    </td>
                  </tr>
                </tfoot>
              </table>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default ProcessTab;
