function Navbar()
{
    return (
        <>
        <nav>
            <div className="admin-nav">
                <div className="admin-nav-logo-and-name">
                    <div className="admin-nav-logo">
                        {/* circular logo */}
                        <div className="company-logo-circular"></div>
                    </div>
                    <div className="admin-nav-name">
                    Preuss I - MGMT <div className="text-blue">.</div>
                    </div>
                </div>
                <div className="admin-nav-button">
                    <a className="admin-nav-btn" href="#">Join</a>
                </div>
            </div>
        </nav>
        </>
    );
}

export default Navbar;