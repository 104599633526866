import React from "react";
import { Helmet } from "react-helmet"; 
const Summary = () => {
  return (
    <>
      <Helmet>
        <title>Summary</title> 
      </Helmet> 
      <h6>
      Summary
      </h6>
    </>
  );
};

export default Summary;
