import logo from './logo.svg';
import './App.css';
import 'remixicon/fonts/remixicon.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.bundle.min.js';
import "./assets/css/style.css";
import "./assets/css/responsive.css";
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import UserLayout from './layouts/UserLayout';
import CompanyLayout from './layouts/CompanyLayout';
import Home from './pages/Home'; 
import AuthLayout from './layouts/AuthLayout';
import Login from './pages/Login';
import CustomerManagement from './pages/CustomerManagement';


import AdminLogin from './pages/adminLogin/adminLogin';
import CompanyManagement from './pages/companyManagement/companyManagement';

import Summary from './pages/Summary';
import Company from './pages/Company';
import CompanyProcess from './pages/CompanyProcess';
function App() {
  return (
    <div className='main-class'>
      <Router>
        <Routes>
          <Route path="/" element={<AdminLogin />}></Route> 

          <Route path='/company-management' element={<CompanyManagement/>}></Route>
          {/* Routes that use the UserLayout */}
          {/* <Route path="/home" element={<UserLayout />}>
            <Route index element={<Home />} /> 
          </Route>   */}
          
          <Route path="/company" element={<CompanyLayout />}>
            <Route index element={<Company />} /> 
          </Route>

          <Route path="/customer-management" element={<UserLayout />}>
            <Route index element={<CustomerManagement />} /> 
          </Route> 

          <Route path="/summary" element={<UserLayout />}>
            <Route index element={<Summary />} /> 
          </Route> 

          <Route path="/company-process" element={<CompanyLayout />}>
            <Route index element={<CompanyProcess />} /> 
          </Route> 
        </Routes>
      </Router>
    </div>
  );
}

export default App;
