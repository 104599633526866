import React, { useState } from 'react';

const InputField = ({ InputId, inputName, InputType = 'text', inputVal = "" }) => {
    const [value, setValue] = useState(inputVal);

    const handleChange = (event) => {
        setValue(event.target.value);
    };

    return (
        <input
            type={InputType}
            name={inputName}
            id={InputId}
            className={`form-control input_field ${value ? 'filled' : ''}`}
            value={value}
            onChange={handleChange}
        />
    );
};

export default InputField;
