import CompanyArea from "./components/companyArea";
import CompanyBackground from "./components/companyBackground";
import CompanySidebar from "./components/companySidebar";
import './companyManagement.css';
function CompanyManagement() {
  return (
    <div background className="company-main-div">
      <CompanyBackground />
      <div className="company-display-page">
        <div className="company-sidebar-main">
          <CompanySidebar />
        </div>
        <div className="company-companyarea-main">
          <CompanyArea />
        </div>
      </div>

    </div>
  );
}

export default CompanyManagement;