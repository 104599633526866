
import React, { useState } from "react";
import { Helmet } from "react-helmet";
import CardHeader from "../components/CardHeader";
import Input from "../components/Input";
import InputField from "../components/InputField";
import ToggleSwitch from "../components/ToggleSwitch";
import { v4 as uuidv4 } from 'uuid'; // Impo

const Company = () => {
  const [isYes, setIsYes] = useState(true); // Initial state set to "Yes"

  const handleToggle = () => {
    setIsYes(!isYes);
  };

  const [rows, setRows] = useState([
    {
      id: uuidv4(),
      department: "General Management",
      short: "GMG",
      area: "Management",
      prz: "-",
      extern: "no",
      size: "1",
      r1: "8",
      r2: "6",
      r3: "3",
      r4: "1",
      r5: "4.5 / 10",
    },
  ]);

  // Function to add a new empty row
  const addRow = () => {
    setRows([
      ...rows,
      {
        id: uuidv4(),
        department: "",
        short: "",
        area: "",
        prz: "",
        extern: "",
        size: "",
        r1: "",
        r2: "",
        r3: "",
        r4: "",
        r5: "",
      },
    ]);
  };

  const removeRow = (id) => {
    setRows((prevRows) => prevRows.filter((row) => row.id !== id));
  };
  return (
    <>
      <Helmet>
        <title>Company Information</title>
      </Helmet>
      <div className="d-flex justify-content-between align-items-center">
        <h4 className="mb-0">Company Information </h4> <button type="submit" className="btn btn-primary px-3 mb-3 bg-green"><i className="ri-check-double-line"></i> Save Changes </button>
      </div>
      <CardHeader cardTitle="Basic Information" />
      <div className="card p-3 mb-3">

        <form action="#">
          <div className="row">
            <Input
              colClass="col-md-4 mb-3"
              id="name"
              name="name"
              label="Name"
              placeholder="Enter name"
            />
            <Input
              id="street"
              name="street"
              label="Street"
              placeholder="Enter street"
              type="text"
            />
            <Input
              id="zipcode"
              name="zipcode"
              label="Zipp – Code"
              placeholder="Enter Zipp – Code"
              type="text"
            />
            <Input
              id="city"
              name="city"
              label="City"
              placeholder="Enter City"
              type="text"
            />
            <Input
              id="business"
              name="business"
              label="Business"
              placeholder="Enter Business"
              type="text"
            />
            <Input
              id="market"
              name="market"
              label="Market"
              placeholder="Enter Market"
              type="text"
            />

          </div>
        </form>
      </div>

      <CardHeader cardTitle="Structure" />
      <div className="table-responsive">

        <table className="table table-bordered custom-table">
          <thead>
            <tr>
              <th style={{ width: '200px' }}>Departments</th>
              <th style={{ width: '100px' }}>Short</th>
              <th style={{ width: '120px' }}>Area</th>
              <th style={{ width: '80px' }}>PRZ</th>
              <th style={{ width: '80px' }}>Extern</th>
              <th style={{ width: '100px' }}>Size</th>
              <th style={{ width: '60px' }}>R1</th>
              <th style={{ width: '60px' }}>R2</th>
              <th style={{ width: '60px' }}>R3</th>
              <th style={{ width: '60px' }}>R4</th>
              <th style={{ width: '100px' }}>R5</th>
              <th style={{ width: '60px' }}>Action</th>
            </tr>
          </thead>
          <tbody>
            {rows.map((row, index) => (
              <tr key={row.id}>
                <td>
                  <InputField
                    InputType="text"
                    InputId={`department_${index}`}
                    inputName={`department_${index}`}
                    inputVal={row.department}
                  />
                </td>
                <td>
                  <InputField
                    InputType="text"
                    InputId={`short_${index}`}
                    inputName={`short_${index}`}
                    inputVal={row.short}
                  />
                </td>
                <td>
                  <InputField
                    InputType="text"
                    InputId={`area_${index}`}
                    inputName={`area_${index}`}
                    inputVal={row.area}
                  />
                </td>
                <td>
                  <InputField
                    InputType="text"
                    InputId={`prz_${index}`}
                    inputName={`prz_${index}`}
                    inputVal={row.prz}
                  />
                </td>
                <td>
                  <InputField
                    InputType="text"
                    InputId={`extern_${index}`}
                    inputName={`extern_${index}`}
                    inputVal={row.extern}
                  />
                </td>
                <td>
                  <InputField
                    InputType="text"
                    InputId={`size_${index}`}
                    inputName={`size_${index}`}
                    inputVal={row.size}
                  />
                </td>
                <td>
                  <InputField
                    InputType="text"
                    InputId={`r1_${index}`}
                    inputName={`r1_${index}`}
                    inputVal={row.r1}
                  />
                </td>
                <td>
                  <InputField
                    InputType="text"
                    InputId={`r2_${index}`}
                    inputName={`r2_${index}`}
                    inputVal={row.r2}
                  />
                </td>
                <td>
                  <InputField
                    InputType="text"
                    InputId={`r3_${index}`}
                    inputName={`r3_${index}`}
                    inputVal={row.r3}
                  />
                </td>
                <td>
                  <InputField
                    InputType="text"
                    InputId={`r4_${index}`}
                    inputName={`r4_${index}`}
                    inputVal={row.r4}
                  />
                </td>
                <td>
                  <InputField
                    InputType="text"
                    InputId={`r5_${index}`}
                    inputName={`r5_${index}`}
                    inputVal={row.r5}
                  />
                </td>
                <td valign="middle">
                  <button type="button" className="remove_btn d-flex bg-transparent fw-bold text-danger" onClick={() => removeRow(row.id)}>
                    <i className="ri-indeterminate-circle-line"></i> <span>Remove</span>
                  </button>
                </td>
              </tr>
            ))}
          </tbody>
          <tfoot>
            <tr>
              <td colSpan="100%" className="text-center">
                <button type="button" className="addmore_btn bg-transparent fw-bold" onClick={addRow}>
                  <i className="ri-add-circle-line"></i> Add More
                </button>
              </td>
            </tr>
          </tfoot>
        </table>
      </div>


      <CardHeader cardTitle="Global Tools" />
      <div className="table-responsive">

        <table className="table table-bordered global-table">
          <thead>
            <tr>
              <th></th>
              <th style={{ width: '100px' }}></th>
              <th style={{ width: '100px' }}></th>
              <th></th>
              <th style={{ width: '100px' }}></th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <th>Integrated Management</th>
              <th>IMS</th>
              <td><ToggleSwitch /></td>
              <td><InputField InputType="text" InputId="" inputName="" inputVal="" /></td>
              <td><InputField InputType="text" InputId="" inputName="" inputVal="" /></td>
            </tr>
            <tr>
              <th>Enterprise Resource Planning</th>
              <th>ERP</th>
              <td><ToggleSwitch /></td>
              <td><InputField InputType="text" InputId="" inputName="" inputVal="" /></td>
              <td><InputField InputType="text" InputId="" inputName="" inputVal="" /></td>
            </tr>
            <tr>
              <th>Computer Aided Quality</th>
              <th>CAQ</th>
              <td><ToggleSwitch /></td>
              <td><InputField InputType="text" InputId="" inputName="" inputVal="" /></td>
              <td><InputField InputType="text" InputId="" inputName="" inputVal="" /></td>
            </tr>
            <tr>
              <th>Customer Relationship Management</th>
              <th>CRM</th>
              <td><ToggleSwitch /></td>
              <td><InputField InputType="text" InputId="" inputName="" inputVal="" /></td>
              <td><InputField InputType="text" InputId="" inputName="" inputVal="" /></td>
            </tr>
            <tr>
              <th>Learning Management</th>
              <th>LMS</th>
              <td><ToggleSwitch /></td>
              <td><InputField InputType="text" InputId="" inputName="" inputVal="" /></td>
              <td><InputField InputType="text" InputId="" inputName="" inputVal="" /></td>
            </tr>
          </tbody>
        </table>
      </div>

    </>
  );
};

export default Company;
