import Navbar from './components/navbar'
import Background from './components/background';
import LoginSection from './components/loginsection';
function AdminLogin()
{
    return (
      <>
      <Background />
      <div className='admin-login-main'>
        <div className='admin-login-area'>
          <div className='re-adjust-width'>
            <div className='admin-login-area-navbar'>
            <Navbar />
            </div>
            <div className='admin-login-area-login-section'>
              <LoginSection />
            </div>
          </div>
        </div>
      </div>
      </>
    );
}


export default AdminLogin;