import React from "react";
import { Helmet } from "react-helmet"; 
import ProcessTab from "../components/ProcessTab";

const CompanyProcess = () => {
  return (
    <>
      <Helmet>
        <title>Company Processes</title>
      </Helmet>
      <div className="d-flex justify-content-between align-items-center">
        <h4 className="mb-0">Company Processes</h4>
        <button type="submit" className="btn btn-primary px-3 mb-3 bg-green">
          <i className="ri-check-double-line"></i> Save Changes
        </button>
      </div>
      <ProcessTab title="Company Processes" description="Management" bgColor="blue-primary" />
      <hr />
      <ProcessTab title="Performance Processes" description="Performance" bgColor="yellow-primary" />
      <hr />
      <ProcessTab title="Supporting Processes" description="Supporting" bgColor="green-primary" />
      {/* Add more ProcessTab instances as needed */}
    </>
  );
};

export default CompanyProcess;
