import React, { useState } from 'react';

const ToggleSwitch = () => {
    const [isYes, setIsYes] = useState(true);

    const handleToggle = () => {
      setIsYes(!isYes);
    };
  return (
    <>
     <div
      onClick={handleToggle}
      style={{
        display: 'inline-block',
        padding: '5px 20px',
        backgroundColor: isYes ? '#d4edda' : '#f8d7da', // Green for "Yes", Red for "No"
        color: isYes ? '#155724' : '#721c24', // Dark green for "Yes", Dark red for "No"
        border: `2px solid ${isYes ? '#c3e6cb' : '#f5c6cb'}`, // Border color changes accordingly
        borderRadius: '5px',
        cursor: 'pointer',
        textAlign: 'center',
        width: '80px',
        userSelect: 'none',
      }}
    >
      {isYes ? 'Yes' : 'No'}
    </div>
    </>
  )
}

export default ToggleSwitch